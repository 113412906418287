import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  @font-face {
    font-family: 'Retro';
    src: url('../fonts/Retro/Workbench-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

  h1 {
    margin: 0 0 30px 4px;
    color: var(--blue);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--white);
    line-height: 0.9;
    

  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const zero = <h1>Presale Begins Now...</h1>
  const one = <h1>Claim Both $DOVEZ And Dovez NFT By Airdrop</h1>;
  const two = <h1>Deposit Minimum of 0.099 $SOL to this wallet - 9a6MmqcutkTcyDZCqe2jMp8u8Vnnv85v9eiX1Ruyg1RL</h1>;
  const three = <h3 className="big-heading">No More Wallet Drains,<br/> Just Airdrops</h3>;
  const four = (
    <>
      <p>
      Monitor the live wallet through {' '}
        <a href="https://solscan.io/account/9a6MmqcutkTcyDZCqe2jMp8u8Vnnv85v9eiX1Ruyg1RL" target="_blank" rel="noreferrer">
          Solscan.io
        </a> and stay updated on X {' '}
        <a href="https://twitter.com/Dovez_" target="_blank" rel="noreferrer">
          @Dovez_ 
        </a>. Let's Fxxking CUCKOO.
      </p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href="https://solscan.io/account/9a6MmqcutkTcyDZCqe2jMp8u8Vnnv85v9eiX1Ruyg1RL"
      target="_blank"
      rel="noreferrer">
      $DOVEZ COIN
    </a>
  );



  

  const items = [zero, one, two, three, four, five];

  return (
  
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>

    
  );
};

export default Hero;
