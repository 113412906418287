import React from 'react';

const IconStar = () => (
  <svg width="2098.8" height="2098.8" viewBox="0 0 2098.8 2098.8">
    <defs>
      <radialGradient id="radial-pattern-0" gradientUnits="userSpaceOnUse" cx="0" cy="0" fx="0" fy="0" r="1049.4" gradientTransform="matrix(1, 0, 0, 1, 1049.4, 1049.4)">
        <stop offset="0" stopColor="rgb(100%, 100%, 100%)" stopOpacity="1"/>
      </radialGradient>
    </defs>
    
  </svg>
);

export default IconStar;
